<template>
  <validation-observer ref="observer" tag="div" v-slot="{invalid}">
    <div class="hero-head">
      <div class="navbar">
        <div class="container">
          <div class="title">
            <h1>{{ isEditing ? 'Actualizar Pregunta' : 'Nueva Pregunta' }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-body">
      <div class="container has-text-left">
        <div class="box">
          <div class="block">
            <validation-provider rules="required" name="title" v-slot="{valid, errors}">
              <b-field label="Título*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-input value="Título de la pregunta" v-model="questionForm.title"></b-input>
              </b-field>
            </validation-provider>
            <validation-provider ref="categoryProvider" rules="required" name="category" v-slot="{valid, errors}">
              <b-field label="Seleccionar categoría*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-autocomplete
                  :data="categoryList"
                  :open-on-focus="true"
                  @focus="fetchCategories()"
                  field="name"
                  placeholder="Buscar categoría..."
                  :loading="fetchingCategories"
                  icon="search"
                  @typing="fetchCategories"
                  @input="validateCategory"
                  clearable
                  @select="handleSelectCategory"
                  v-model="selectedCategory">
                  <template #empty>Sin resultados</template>
                  <template slot-scope="props">
                    <div class="media">
                      <div class="media-content">{{ props.option.name }}</div>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </validation-provider>
            <validation-provider rules="required" name="questionType" v-slot="{valid, errors}">
              <b-field label="Tipo de pregunta*" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                       :message="errors">
                <b-select @input="handleSelectQuestionType" placeholder="Tipo de pregunta"
                          v-model="questionForm.type.id">
                  <option v-for="(questionType, i) in questionTypeList" :value="questionType.id" :key="i">
                    {{ questionType.type }}
                  </option>
                </b-select>
              </b-field>
            </validation-provider>
            <validation-provider v-if="questionForm.type.id === 2" rules="required" name="questionType" v-slot="{valid, errors}">
            <b-field label="Enter some tags" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-taginput
                v-model="selectedOptions"
                ref="taginput"
                icon="pen"
                placeholder="Añadir opción" :create-tag="handleAddOption" @remove="handleRemoveOption">
              </b-taginput>
            </b-field>
            </validation-provider>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot">
      <div class="buttons is-right p-6">
        <b-button type="is-dark" @click="finishAddingQuestion">Volver</b-button>
        <b-button type="is-primary" :disabled="invalid" @click="saveOrUpdateQuestion">{{
            isEditing ? 'Actualizar' :
              'Guardar'
          }}
        </b-button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { debounce } from 'debounce'

export default {
  name: 'AddQuestion',
  props: {
    isEditing: {
      type: Boolean,
      required: true
    },
    question: {
      type: Object,
      required: false
    }
  },
  created () {
    this.fetchQuestionTypes()
    if (this.isEditing) {
      this.questionForm = this.lodash.cloneDeep(this.question)
      this.selectedOptions = this.questionForm.options.map(o => o.name)
      this.selectedCategory = this.questionForm.category.name
    }
  },
  data () {
    return {
      categoryList: [],
      fetchingCategories: false,
      questionForm: {
        id: null,
        category: {
          id: null
        },
        options: [],
        title: null,
        type: {
          id: null
        }
      },
      questionTypeList: [],
      selectedCategory: null,
      selectedOptions: []
    }
  },
  methods: {
    fetchCategories: debounce(function (categoryName) {
      this.fetchingCategories = true
      this.axios.get(process.env.VUE_APP_API_GET_CATEGORY, {
        params: {
          searchQuery: categoryName && categoryName !== '' ? 'name$' + categoryName : null
        }
      }).then(response => {
        this.categoryList = response.data.content
      }).catch(err => {
        console.error('Error al obtener las categorías', err)
      }).finally(() => {
        this.fetchingCategories = false
      })
    },
    500),
    fetchQuestionTypes () {
      this.axios.get(process.env.VUE_APP_API_GET_QUESTION_TYPE).then(response => {
        this.questionTypeList = response.data.content
      }).catch(err => {
        console.error('No se pueden obtener los tipos de pregunta', err)
      })
    },
    finishAddingQuestion () {
      this.$emit('finishedAddingQuestion')
    },
    handleAddOption (option) {
      let existOriginally = false
      if (this.isEditing) {
        existOriginally = this.question.options.find(o => {
          return o.name === option
        })
      }
      const existInForm = this.questionForm.options.findIndex(o => o.name === option)
      if (existInForm === -1) {
        if (existOriginally) {
          this.questionForm.options.push(existOriginally)
        } else {
          this.questionForm.options.push({ name: option })
        }
      }
      return option
    },
    handleSelectCategory (option) {
      this.questionForm.category.id = option.id
    },
    handleRemoveOption (option) {
      const index = this.questionForm.options.findIndex(o => o.name === option)
      if (index !== -1) {
        this.questionForm.options.splice(index, 1)
        console.log('Question length', this.question.options.length)
      }
    },
    saveQuestion () {
      this.axios.post(process.env.VUE_APP_API_CREATE_QUESTION, this.questionForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addQuestion.created.title'),
          message: this.$i18n.t('addQuestion.created.message')
        })
        this.$emit('finishedAddingQuestion', true)
      }).catch(err => {
        console.error('No se puede crear la pregunta', err)
      })
    },
    saveOrUpdateQuestion () {
      if (this.isEditing) {
        this.updateQuestion()
      } else {
        this.saveQuestion()
      }
    },
    updateQuestion () {
      this.axios.put(process.env.VUE_APP_API_UPDATE_QUESTION, this.questionForm).then(() => {
        this.$notify.success({
          title: this.$i18n.t('addQuestion.updated.title'),
          message: this.$i18n.t('addQuestion.updated.message')
        })
        this.$emit('finishedAddingQuestion', true)
      }).catch(err => {
        console.error('No se puede actualizar la pregunta', err)
      })
    },
    handleSelectQuestionType () {
    },
    validateCategory (e) {
      this.$refs.categoryProvider.validate(e)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
