<template>
  <section class="hero is-bg-gray is-fullheight-with-navbar p-6">
    <div id="questionTable" v-if="!addQuestion">
      <div class="hero-head">
        <div class="navbar">
          <div class="container">
            <div class="title">
              <h1>Repositorio de Preguntas</h1>
            </div>
            <div id="navbarMenuHeroB" class="navbar-menu">
              <div class="navbar-end">
              <span class="navbar-item">
                  <b-button class="button is-info is-primary" @click="addQuestion=true">
                    <v-icon icon="plus" class="g-icon"></v-icon>
                    <span>Añadir pregunta</span>
                  </b-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-body">
        <div class="container">
          <div class="block">
            <section class="box">
              <div class="block is-full">
                <b-field grouped group-multiline position="is-right">
                  <b-field>
                    <b-input placeholder="Buscar pregunta..." type="search" icon="search"
                             v-model="filters.questionSearch"></b-input>
                    <b-select v-model="filters.category" placeholder="Categoría"
                              :loading="loadingCategories">
                      <option v-for="(category, i) in categories" :value="category.name" :key="i">{{
                          category.name
                        }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field>
                    <p class="control">
                      <b-button class="button is-link" @click="fetchQuestions">
                        <v-icon icon="filter" class="g-icon"></v-icon>
                        Filtrar
                      </b-button>
                    </p>
                  </b-field>
                </b-field>
              </div>
              <div class="block m-4 has-text-left">
                <b-table
                  backend-sorting
                  backend-pagination
                  :data="data"
                  @sort="handleTableSort"
                  :hoverable="isHoverable"
                  :loading="loadingQuestions"
                  :current-page.sync="currentPage">
                  <b-table-column  field="title" label="Pregunta" sortable v-slot="props">
                    {{ props.row.title }}
                  </b-table-column>
                  <b-table-column centered field="id" label="REF" sortable v-slot="props" width="40">
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column centered field="type.type" label="Tipo" sortable v-slot="props">
                    {{ props.row.type.type }}
                  </b-table-column>
                  <b-table-column centered field="category.name" label="Categoría" sortable v-slot="props">
                    {{ props.row.category.name }}
                  </b-table-column>
                  <b-table-column centered label="Acciones" v-slot="props" width="200">
                    <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                      <el-button size="mini" type="warning" circle @click="handleUpdateQuestion(props.row)">
                        <v-icon icon="pen"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Duplicar" placement="top">
                      <el-button size="mini" type="primary" circle @click="handleDuplicateQuestion(props.row)">
                        <v-icon icon="copy"></v-icon>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                      <el-button size="mini" type="danger" circle @click="handleDeleteQuestion(props.row)">
                        <v-icon icon="trash"></v-icon>
                      </el-button>
                    </el-tooltip>
                  </b-table-column>
                </b-table>
              </div>
              <div class="block">
                <el-pagination class="mt-5"
                               :hide-on-single-page="true"
                               @size-change="handlePageSizeChange"
                               @current-change="handlePageChange"
                               :current-page.sync="currentPage"
                               :page-sizes="[20, 50, 100, 200]"
                               :page-size="pageSize"
                               layout="total, sizes, prev, pager, next"
                               :total="totalElements">
                </el-pagination>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div id="addQuestion" v-if="addQuestion">
      <add-question @finishedAddingQuestion="handleQuestionAdded" :is-editing="isEditing"
                    :question="selectedQuestion"></add-question>
    </div>
  </section>
</template>

<script>
import AddQuestion from '../components/question/AddQuestion'

export default {
  components: { AddQuestion },
  data () {
    return {
      addQuestion: false,
      categories: [],
      currentPage: 1,
      data: [],
      filters: {
        questionSearch: '',
        category: null
      },
      isEditing: false,
      isHoverable: true,
      loadingQuestions: false,
      loadingCategories: false,
      pageSize: 20,
      rangeBefore: 3,
      rangeAfter: 1,
      selectedQuestion: null,
      sortOption: 'id,desc',
      totalElements: 200
    }
  },
  created () {
    this.fetchQuestions()
    this.fetchCategories()
  },
  methods: {
    fetchCategories () {
      this.loadingCategories = true
      this.axios.get(process.env.VUE_APP_API_GET_CATEGORY, {
        params: {
          page: 0,
          size: 9999
        }
      }).then(response => {
        this.categories = response.data.content
      }).catch(err => {
        console.error('No se pueden obtener las categorías', err)
      }).finally(() => {
        this.loadingCategories = false
      })
    },
    fetchQuestions () {
      this.loadingQuestions = true
      this.axios.get(process.env.VUE_APP_API_GET_QUESTION, {
        params: {
          page: this.currentPage - 1,
          size: this.pageSize,
          sort: this.sortOption,
          searchQuery: this.getSearchQuery()
        }
      }).then(response => {
        this.totalElements = response.data.totalElements
        this.data = response.data.content
      }).catch(err => {
        console.error('No se han podido obtener las preguntas', err)
      }).finally(() => {
        this.loadingQuestions = false
      })
    },
    getSearchQuery () {
      let searchQuery = ''
      if (this.filters.questionSearch) {
        searchQuery += 'title$' + this.filters.questionSearch
      }
      if (this.filters.category) {
        searchQuery += ',category.name:' + this.filters.category
      }

      return searchQuery
    },
    handleDeleteQuestion (question) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Pregunta',
        message: '¿Está seguro de que quiere <b>eliminar</b> la pregunta? Esto eliminará también todas las respuestas que se hayan hecho sobre ésta',
        confirmText: 'Eliminar pregunta',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.axios.delete(process.env.VUE_APP_API_DELETE_QUESTION, {
            params: {
              questionId: question.id
            }
          }).then(() => {
            this.fetchQuestions()
            this.$notify.success({
              title: 'Pregunta eliminada',
              message: 'La pregunta se ha eliminado correctamente'
            })
          }).catch(err => {
            console.error('No se puede eliminar la pregunta', err)
          })
        }
      })
    },
    handleDuplicateQuestion (question) {
      this.$confirm('¿Está seguro/a de que desea duplicar la pregunta?', 'Atención', {
        confirmButtonText: 'Duplicar',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        this.axios.post(process.env.VUE_APP_API_DUPLICATE_QUESTION, null, {
          params: {
            questionId: question.id
          }
        }).then(() => {
          this.fetchQuestions()
          this.$notify.success({
            title: 'Pregunta duplicada',
            message: 'La pregunta se ha duplicado correctamente'
          })
        }).catch(err => {
          console.log('No se puede duplicar la pregunta', err)
        })
      })
    },
    handlePageChange (page) {
      this.currentPage = page
      this.fetchQuestions()
    },
    handlePageSizeChange (pageSize) {
      this.currentPage = 1
      this.pageSize = pageSize
      this.fetchQuestions()
    },
    handleQuestionAdded (isCreated) {
      if (isCreated) {
        this.fetchQuestions()
      }
      this.resetStatus()
    },
    handleSelectQuestion (value) {
      this.filters.selectedQuestion = value
    },
    handleTableSort (field, order) {
      this.sortOption = field + ',' + order
      this.fetchQuestions()
    },
    handleUpdateQuestion (question) {
      this.isEditing = true
      this.selectedQuestion = question
      this.addQuestion = true
    },
    resetStatus () {
      this.addQuestion = false
      this.isEditing = false
      this.selectedQuestion = false
      this.sortOption = 'id,desc'
    }
  }
}
</script>

<style lang="scss">

</style>
