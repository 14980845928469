var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-bg-gray is-fullheight-with-navbar p-6"
  }, [!_vm.addQuestion ? _c('div', {
    attrs: {
      "id": "questionTable"
    }
  }, [_c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "navbar-menu",
    attrs: {
      "id": "navbarMenuHeroB"
    }
  }, [_c('div', {
    staticClass: "navbar-end"
  }, [_c('span', {
    staticClass: "navbar-item"
  }, [_c('b-button', {
    staticClass: "button is-info is-primary",
    on: {
      "click": function click($event) {
        _vm.addQuestion = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "plus"
    }
  }), _c('span', [_vm._v("Añadir pregunta")])], 1)], 1)])])])])]), _c('div', {
    staticClass: "hero-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('section', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "block is-full"
  }, [_c('b-field', {
    attrs: {
      "grouped": "",
      "group-multiline": "",
      "position": "is-right"
    }
  }, [_c('b-field', [_c('b-input', {
    attrs: {
      "placeholder": "Buscar pregunta...",
      "type": "search",
      "icon": "search"
    },
    model: {
      value: _vm.filters.questionSearch,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "questionSearch", $$v);
      },
      expression: "filters.questionSearch"
    }
  }), _c('b-select', {
    attrs: {
      "placeholder": "Categoría",
      "loading": _vm.loadingCategories
    },
    model: {
      value: _vm.filters.category,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "category", $$v);
      },
      expression: "filters.category"
    }
  }, _vm._l(_vm.categories, function (category, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": category.name
      }
    }, [_vm._v(_vm._s(category.name) + " ")]);
  }), 0)], 1), _c('b-field', [_c('p', {
    staticClass: "control"
  }, [_c('b-button', {
    staticClass: "button is-link",
    on: {
      "click": _vm.fetchQuestions
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "filter"
    }
  }), _vm._v(" Filtrar ")], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "block m-4 has-text-left"
  }, [_c('b-table', {
    attrs: {
      "backend-sorting": "",
      "backend-pagination": "",
      "data": _vm.data,
      "hoverable": _vm.isHoverable,
      "loading": _vm.loadingQuestions,
      "current-page": _vm.currentPage
    },
    on: {
      "sort": _vm.handleTableSort,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  }, [_c('b-table-column', {
    attrs: {
      "field": "title",
      "label": "Pregunta",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.title) + " ")];
      }
    }], null, false, 1662046397)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "id",
      "label": "REF",
      "sortable": "",
      "width": "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.id) + " ")];
      }
    }], null, false, 2188296272)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "type.type",
      "label": "Tipo",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.type.type) + " ")];
      }
    }], null, false, 2535474515)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "field": "category.name",
      "label": "Categoría",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v(" " + _vm._s(props.row.category.name) + " ")];
      }
    }], null, false, 1004680868)
  }), _c('b-table-column', {
    attrs: {
      "centered": "",
      "label": "Acciones",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Editar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "warning",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleUpdateQuestion(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "pen"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Duplicar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "primary",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleDuplicateQuestion(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "copy"
          }
        })], 1)], 1), _c('el-tooltip', {
          staticClass: "item",
          attrs: {
            "effect": "dark",
            "content": "Eliminar",
            "placement": "top"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "type": "danger",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteQuestion(props.row);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)], 1)];
      }
    }], null, false, 1058392739)
  })], 1)], 1), _c('div', {
    staticClass: "block"
  }, [_c('el-pagination', {
    staticClass: "mt-5",
    attrs: {
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-sizes": [20, 50, 100, 200],
      "page-size": _vm.pageSize,
      "layout": "total, sizes, prev, pager, next",
      "total": _vm.totalElements
    },
    on: {
      "size-change": _vm.handlePageSizeChange,
      "current-change": _vm.handlePageChange,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  })], 1)])])])])]) : _vm._e(), _vm.addQuestion ? _c('div', {
    attrs: {
      "id": "addQuestion"
    }
  }, [_c('add-question', {
    attrs: {
      "is-editing": _vm.isEditing,
      "question": _vm.selectedQuestion
    },
    on: {
      "finishedAddingQuestion": _vm.handleQuestionAdded
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h1', [_vm._v("Repositorio de Preguntas")])]);
}]

export { render, staticRenderFns }