var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "hero-head"
        }, [_c('div', {
          staticClass: "navbar"
        }, [_c('div', {
          staticClass: "container"
        }, [_c('div', {
          staticClass: "title"
        }, [_c('h1', [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar Pregunta' : 'Nueva Pregunta'))])])])])]), _c('div', {
          staticClass: "hero-body"
        }, [_c('div', {
          staticClass: "container has-text-left"
        }, [_c('div', {
          staticClass: "box"
        }, [_c('div', {
          staticClass: "block"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "title"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var valid = _ref2.valid,
                  errors = _ref2.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Título*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Título de la pregunta"
                },
                model: {
                  value: _vm.questionForm.title,
                  callback: function callback($$v) {
                    _vm.$set(_vm.questionForm, "title", $$v);
                  },
                  expression: "questionForm.title"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('validation-provider', {
          ref: "categoryProvider",
          attrs: {
            "rules": "required",
            "name": "category"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var valid = _ref3.valid,
                  errors = _ref3.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Seleccionar categoría*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-autocomplete', {
                attrs: {
                  "data": _vm.categoryList,
                  "open-on-focus": true,
                  "field": "name",
                  "placeholder": "Buscar categoría...",
                  "loading": _vm.fetchingCategories,
                  "icon": "search",
                  "clearable": ""
                },
                on: {
                  "focus": function focus($event) {
                    return _vm.fetchCategories();
                  },
                  "typing": _vm.fetchCategories,
                  "input": _vm.validateCategory,
                  "select": _vm.handleSelectCategory
                },
                scopedSlots: _vm._u([{
                  key: "empty",
                  fn: function fn() {
                    return [_vm._v("Sin resultados")];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function fn(props) {
                    return [_c('div', {
                      staticClass: "media"
                    }, [_c('div', {
                      staticClass: "media-content"
                    }, [_vm._v(_vm._s(props.option.name))])])];
                  }
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "questionType"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var valid = _ref4.valid,
                  errors = _ref4.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Tipo de pregunta*",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-select', {
                attrs: {
                  "placeholder": "Tipo de pregunta"
                },
                on: {
                  "input": _vm.handleSelectQuestionType
                },
                model: {
                  value: _vm.questionForm.type.id,
                  callback: function callback($$v) {
                    _vm.$set(_vm.questionForm.type, "id", $$v);
                  },
                  expression: "questionForm.type.id"
                }
              }, _vm._l(_vm.questionTypeList, function (questionType, i) {
                return _c('option', {
                  key: i,
                  domProps: {
                    "value": questionType.id
                  }
                }, [_vm._v(" " + _vm._s(questionType.type) + " ")]);
              }), 0)], 1)];
            }
          }], null, true)
        }), _vm.questionForm.type.id === 2 ? _c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "questionType"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var valid = _ref5.valid,
                  errors = _ref5.errors;
              return [_c('b-field', {
                attrs: {
                  "label": "Enter some tags",
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-taginput', {
                ref: "taginput",
                attrs: {
                  "icon": "pen",
                  "placeholder": "Añadir opción",
                  "create-tag": _vm.handleAddOption
                },
                on: {
                  "remove": _vm.handleRemoveOption
                },
                model: {
                  value: _vm.selectedOptions,
                  callback: function callback($$v) {
                    _vm.selectedOptions = $$v;
                  },
                  expression: "selectedOptions"
                }
              })], 1)];
            }
          }], null, true)
        }) : _vm._e()], 1)])])]), _c('div', {
          staticClass: "hero-foot"
        }, [_c('div', {
          staticClass: "buttons is-right p-6"
        }, [_c('b-button', {
          attrs: {
            "type": "is-dark"
          },
          on: {
            "click": _vm.finishAddingQuestion
          }
        }, [_vm._v("Volver")]), _c('b-button', {
          attrs: {
            "type": "is-primary",
            "disabled": invalid
          },
          on: {
            "click": _vm.saveOrUpdateQuestion
          }
        }, [_vm._v(_vm._s(_vm.isEditing ? 'Actualizar' : 'Guardar') + " ")])], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }